import React from "react";
import { connect } from "react-redux";
import Img from "gatsby-image";
import { Link, graphql } from "gatsby";

const Blog = (props) => {
  const { numPostsPages } = props.pageContext;
  const { langu, data } = props;

  let langua = "";
  if (langu === "es-CA") {
    langua = "fr";
  } else {
    langua = langu.substring(0, 2).toLowerCase();
  }

  return (
    <>
      <div className="section" style={{ background: "#eaeae8" }}>
        <div className="container">
          <h1 className="title is-spaced is-3">Blog</h1>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="columns is-multiline">
            {langu &&
              data.Prodotti.edges.map((item) => (
                <Link
                  to={`/${item.node.data.slug}`}
                  key={item.node.data.slug}
                  className="column is-one-third"
                >
                  <div className="card is-shadowless">
                    <div className="card-image">
                      {item.node.data.Immagine.localFiles.length ? (
                        <Img
                          fluid={
                            item.node.data.Immagine.localFiles[0]
                              .childImageSharp.fluid
                          }
                        />
                      ) : null}
                    </div>
                    <div className="card-content" style={{ marginTop: "auto" }}>
                      <p className="is-size-6 has-text-weight-bold">
                        {item.node.data[`Titolo_${langua}`]}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
          </div>

          {numPostsPages > 1 && (
            <nav
              className="pagination is-rounded is-centered"
              role="navigation"
            >
              <ul className="pagination-list">
                {Array.from({ length: numPostsPages }, (_, i) => (
                  <li key={`pagination-number${i + 1}`}>
                    <Link
                      className="pagination-link"
                      activeClassName="is-current"
                      to={`/blog/${i === 0 ? "" : i + 1}/`}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    langu: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(Blog);

export const blogQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    Prodotti: allAirtableBlog(
      filter: { table: { eq: "blog" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          data {
            Titolo_it
            Titolo_es
            Testo_en
            Testo_fr
            slug
            Testo_es
            Testo_it
            Titolo_fr
            Titolo_en
            Immagine {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
